import React, { FC, SVGAttributes } from 'react'

const ArrowNextIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="none" viewBox="0 0 16 14">
        <path
            {...props}
            strokeWidth="0.5"
            d="M12.139 6.125H1.75a.875.875 0 000 1.75h10.388L8.01 12.004a.875.875 0 00-.019 1.255h0a.875.875 0 001.256-.018l5.622-5.623h0a.875.875 0 000-1.236h0L9.247.758A.875.875 0 108.01 1.996l4.129 4.129z"
        />
    </svg>
)

export default ArrowNextIcon
