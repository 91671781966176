export const DEFAULT_PORTION_SIZE = 'single'
export const DEFAULT_DELIVERY_INTERVAL = 4
export const DEFAULT_QUANTITY = 6
export const MINIMUM_QUANTITY = 6
export const FREE_SHIPPING_MEALS_THRESHOLD = 8
export const FREE_SHIPPING_THRESHOLD = 58
export const MEAL_BULK_DISCOUNT_THRESHOLDS: { [key: number]: number } = {
    12: 10,
    10: 5,
}
