import { ImageLoaderProps } from 'next/image'

const cfImageUrl = process.env.NEXT_PUBLIC_CLOUDFLARE_IMAGE_URL ?? ''

const normalizeSrc = (src: string) => {
    if (src.startsWith('/')) {
        if (cfImageUrl) {
            return encodeURIComponent(`${cfImageUrl}${src}`)
        }

        return src.slice(1)
    }

    return encodeURIComponent(src)
}

export default function cloudflareLoader({ src, width, quality }: ImageLoaderProps) {
    const params = ['format=auto', `width=${width}`]

    if (quality) {
        params.push(`quality=${quality}`)
    }

    const paramsString = params.join(',')

    return `${cfImageUrl}/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}
