import { gtagEnabled } from '../tracking/index'

type Item = {
    item_name: string
}
export const trackRemoveFromCart = (items: Item[]) => {
    if (gtagEnabled()) {
        gtag('event', 'remove_from_cart', {
            items,
        })
    }
}
