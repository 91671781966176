import React, { FC, SVGAttributes } from "react"

const PlusIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    fill="none"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      stroke="current"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 1v10m5-5H1"
    />
  </svg>

export default PlusIcon
