type TitleSortProps = {
    title: string
} & any

const titleSort = (a: TitleSortProps, b: TitleSortProps) => {
    if (a.title > b.title) {
        return 1
    }
    if (b.title > a.title) {
        return -1
    }
    return 0
}

export default titleSort
