import { fbqEnabled, gtagEnabled, klaviyoEnabled } from '../tracking/index'
import { Product, Variant } from '../../types/medusa'

export const trackAddToCart = (variant: Variant, handle: string, title: string, price: string, thumbnail: string) => {
    if (gtagEnabled()) {
        gtag('event', 'add_to_cart', {
            items: [{ item_name: title }],
        })
    }
    if (fbqEnabled()) {
        fbq('track', 'AddToCart', {
            items: [{ item_name: title }],
        })
    }
    if (klaviyoEnabled()) {
        window._learnq.push([
            'track',
            'Added to Cart',
            {
                $value: price,
                AddedItemProductName: title,
                AddedItemProductID: variant.product_id,
                AddedItemPrice: price,
                items: [
                    {
                        ProductName: title,
                        ProductID: variant.product_id,
                        ItemPrice: price,
                        ImageURL: thumbnail,
                        Quantity: 1,
                        ProductURL: `https://www.goodchef.nl/gerechten/${handle}`,
                    },
                ],
            },
        ])
    }
}
