import React, { FC, SVGAttributes } from 'react'

const TrashIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" fill="none" viewBox="0 0 12 14" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9 1.985v.152c.866.079 1.729.193 2.585.341a.5.5 0 11-.17.985l-.14-.023-.67 8.713A2 2 0 018.611 14H3.39a2 2 0 01-1.994-1.847L.725 3.44l-.14.023a.5.5 0 11-.17-.985C1.272 2.33 2.134 2.216 3 2.137v-.152C3 .943 3.809.052 4.877.018a35.108 35.108 0 012.246 0C8.192.052 9 .943 9 1.985zm-4.09-.968a34.13 34.13 0 012.181 0A.953.953 0 018 1.985v.076a32.993 32.993 0 00-4 0v-.076c0-.529.406-.952.91-.968zm-.237 3.964a.5.5 0 10-1 .038l.231 6a.5.5 0 001-.038l-.231-6zm3.653.038a.5.5 0 10-.999-.038l-.231 6a.5.5 0 101 .038l.23-6z"
            clipRule="evenodd"
        />
    </svg>
)

export default TrashIcon
