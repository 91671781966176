import MinusIconComponent from '../MinusIconComponent'
import { trackRemoveFromCart } from 'web/src/utils/tracking/trackRemoveFromCart'
import classNames from 'classnames'
import TrashIcon from '../../icons/trashIcon'
import LoadingIcon from '../../icons/loadingIcon'
import PlusIconComponent from '../PlusIconComponent'
import { trackAddToCart } from 'web/src/utils/tracking/trackAddToCart'
import React from 'react'
import { useStore } from 'web/src/lib/context/store-context'
import { useProductActions } from 'web/src/lib/context/product-context'
import { CartSubscriptionContext } from 'web/src/types/medusa'
import { useCart } from 'medusa-react'

const ChangeQuantity = ({ lockAmount }: { lockAmount?: boolean }) => {
    const { cart } = useCart()
    const { updateItem, addItem, deleteItem, quantityUpdating, mealTypeId } = useStore()
    const { product } = useProductActions()

    const cartItem = cart?.items?.find(item => item?.variant?.product_id === product?.id)
    const quantity = cartItem?.quantity ?? 0
    const cartSubscriptionContext = cart?.context?.subscription as CartSubscriptionContext
    const isMeal = product?.type_id === mealTypeId
    const variant = isMeal ? product.variants.find(variant => variant.title === 'single') : product.variants[0]

    const cartLineItem = cart?.items?.find(item => item.variant_id == variant?.id)
    const updateQuantity = (quantity: number) => {
        if (cartLineItem) {
            updateItem({ lineId: cartLineItem.id, quantity })
        } else if (variant?.id) {
            addItem({ variantId: variant.id, quantity })
        }
    }

    const stock = variant?.inventory_quantity
    let outOfStockMessage = ''
    if (stock === undefined) {
        outOfStockMessage = 'Portiegrootte niet beschikbaar'
    } else if (stock === 0) {
        outOfStockMessage = 'Uitverkocht, snel weer terug'
    }

    const isLoading = !!(quantityUpdating?.[`${variant?.id}`] || quantityUpdating?.[`${cartLineItem?.id}`])

    return (
        <div>
            {stock ? (
                <div className="flex flex-row items-center mt-[7px] justify-between z-50">
                    <div className="flex items-center w-full relative">
                        {!lockAmount && (
                            <>
                                {quantity !== 1 && quantity >= 0 && (
                                    <MinusIconComponent
                                        disabled={quantity === 0 || isLoading}
                                        onClick={e => {
                                            e.preventDefault()
                                            e.stopPropagation()

                                            if (quantity > 0) {
                                                updateQuantity(quantity - 1)
                                                trackRemoveFromCart([{ item_name: product.title }])
                                            }
                                        }}
                                    />
                                )}
                                {quantity === 1 && (
                                    <div
                                        aria-label={'remove this item'}
                                        className={classNames(
                                            `group/2 lg:hover:rotate-[-6deg] duration-200 ease lg:hover:after:bg-green-brand flex items-center justify-center w-8 h-8 flex-shrink-0 [&>svg]:z-10 after:content-[''] after:w-[30px] after:h-[30px] after:absolute after:rounded-full after:bg-dark lg:hover:cursor-pointer`,
                                            {
                                                'opacity-50 lg:hover:bg-dark pointer-events-none': isLoading,
                                            },
                                        )}
                                        onClick={e => {
                                            e.preventDefault()
                                            e.stopPropagation()

                                            if (cartLineItem) {
                                                deleteItem(cartLineItem.id)
                                                trackRemoveFromCart([{ item_name: product.title }])
                                            }
                                        }}
                                    >
                                        <TrashIcon className="text-white lg:group-hover/2:text-black pointer-events-none" />
                                    </div>
                                )}
                            </>
                        )}
                        <span
                            className={classNames(
                                `relative text-dark w-10 flex items-center justify-center font-bold`,
                                {
                                    'border-[1px] border-grey/20 rounded-full w-[40px] h-[30px] pointer-events-none':
                                        lockAmount,
                                },
                            )}
                        >
                            {isLoading ? <LoadingIcon width={20} height={20} /> : quantity}
                        </span>
                        {!lockAmount && (
                            <PlusIconComponent
                                disabled={quantity >= stock || isLoading}
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    updateQuantity(quantity + 1)

                                    if (variant && cart) {
                                        trackAddToCart(
                                            variant,
                                            product?.handle || '',
                                            product?.title || '',
                                            (variant?.prices?.[0]?.amount / 100).toString(),
                                            product?.thumbnail || '',
                                        )
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <p className="mt-[10px] h-[30px] lg:hover:cursor-pointer text-xs font-normal text-black items-center flex">
                    {outOfStockMessage}
                </p>
            )}
        </div>
    )
}

export default ChangeQuantity
