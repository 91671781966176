import React from 'react'
import type { PriceProps } from './types'
import getPriceInteger from '../../../utils/getPriceInteger'
import getPriceFractional from '../../../utils/getPriceFractional'

const Price = ({ price, isDiscountPrice = false }: PriceProps) => {
    const formatted = (price / 100).toFixed(2)
    const parts = formatted.split('.')

    return (
        <div
            className={`relative flex items-start ${
                isDiscountPrice
                    ? 'mr-2 after:content-[""] after:block after:h-[1.5px] after:bg-grey after:w-full after:absolute after:-rotate-[13deg] after:top-[5px]'
                    : ''
            }`}
        >
            <span
                className={`${
                    isDiscountPrice
                        ? 'text-grey text-dark text-[12px] font-bold leading-[12px]'
                        : 'text-dark text-[15px] font-bold leading-[15px]'
                } tracking-wide`}
            >
                {parts[0]},
            </span>
            <span
                className={`${
                    isDiscountPrice
                        ? 'text-grey text-dark text-[9px] font-bold leading-[9px]'
                        : 'text-dark text-[11px] font-bold leading-[11px]'
                } tracking-wide`}
            >
                {parts[1]}
            </span>
        </div>
    )
}

export default Price
