'use client'

import React from 'react'
import Highlight from '../Atoms/Highlight'
import { ProductListProps } from './types'
import Price from '../Atoms/Price'
import { useStore } from 'web/src/lib/context/store-context'
import { MEAL_BULK_DISCOUNT_THRESHOLDS } from '../../utils/discountLogic'
import ChangeQuantity from '../ChangeQuantity'
import { useProductActions } from 'web/src/lib/context/product-context'
import Image from 'next/image'

const ProductList = ({ lockAmount, lineItem, highlight, isHistory }: ProductListProps) => {
    const { mealsInCart } = useStore()
    const { product } = useProductActions()

    const price = lineItem.total && lineItem.total > 0 ? lineItem.total / lineItem.quantity : 0
    const originalPrice =
        lineItem.original_total && lineItem.original_total !== lineItem.total
            ? lineItem.original_total / lineItem.quantity
            : null

    const image = product.thumbnail ? (
        <Image src={product.thumbnail} alt={product.title} width="192" height="235" />
    ) : undefined

    const mealThresh = Object.keys(MEAL_BULK_DISCOUNT_THRESHOLDS).map(threshold => {
        return {
            threshold: Number(threshold),
            discount: MEAL_BULK_DISCOUNT_THRESHOLDS[Number(threshold)],
        }
    })

    return (
        <div className="flex w-full gap-4">
            <div className="relative flex">
                <div className="absolute left-1 top-1 z-10">
                    {highlight && <Highlight icon={highlight.icon} title={highlight.title} />}
                </div>
                <div className="w-20 h-auto overflow-hidden rounded-10 [&>img]:object-cover [&>img]:min-h-full">
                    {image}
                </div>
            </div>

            <div className="flex flex-col w-full justify-center">
                <span className="font-bold text-base leading-[19px] mb-2">{product?.title}</span>
                {!isHistory ? (
                    <div className="flex justify-between">
                        <ChangeQuantity lockAmount={!!lockAmount} />
                        <div className="flex">
                            {mealsInCart >= mealThresh[0].threshold && (
                                <Price isDiscountPrice price={originalPrice || price} />
                            )}
                            <Price
                                price={
                                    mealsInCart >= mealThresh[1].threshold
                                        ? (originalPrice || price) * ((100 - mealThresh[1].discount) / 100)
                                        : mealsInCart >= mealThresh[0].threshold
                                        ? (originalPrice || price) * ((100 - mealThresh[0].discount) / 100)
                                        : originalPrice || price
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-center rounded-[600px] w-fit border-[1px] border-grey/20 min-w-[48px] h-[30px]">
                        <p className="text-dark font-bold text-[15px]">{lineItem.quantity} x</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductList
