'use client'
import React from 'react'
import { PlusIconProps } from './types'
import PlusIcon from '../../icons/plusIcon'
import classNames from 'classnames'

const PlusIconComponent = ({ onClick, disabled }: PlusIconProps) => {
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
            onClick(e)
        } else {
            e.preventDefault()
        }
    }

    return (
        <div
            role="note"
            aria-label="Verhoog aantal"
            className={classNames(
                `duration-200 ease group/2 [&>svg]:stroke-white flex items-center justify-center w-8 h-8 flex-shrink-0 [&>svg]:z-10 after:content-[''] after:w-[30px] after:h-[30px] after:absolute after:rounded-full after:bg-dark`,
                { 'opacity-50 cursor-not-allowed': disabled },
                { 'lg:hover:after:bg-green-brand lg:hover:cursor-pointer lg:hover:rotate-[-6deg]': !disabled }
            )}
            onClick={handleClick}
        >
            <PlusIcon
                className={classNames(
                    'pointer-events-none',
                    { '': disabled },
                    { 'lg:group-hover/2:stroke-black': !disabled }
                )}
            />
        </div>
    )
}

export default PlusIconComponent
