import React from 'react'
import type { HighlightProps } from './types'

const Highlight = ({ title, icon }: HighlightProps) => {
    return (
        <div className="bg-green-a rounded-[10px] w-fit text-white flex items-center h-[30px] p-2.5 relative z-[1]">
            {icon && <div className="mr-2">{icon}</div>}
            <span className="uppercase font-bold text-xs tracking-[0.06em]">{title}</span>
        </div>
    )
}

export default Highlight
